<template>
  <div class="d-inline">
    <div
      @click="showModal = true"
      class="ma-0 pa-0 d-inline"
    >
      <slot>
        <b-button
          v-if="edit"
          variant="success"
          block
        >
          <b-icon icon="pencil"></b-icon>
          Edit Employee
        </b-button>
        <b-button
          variant="success"
          v-else
          block
        >
          <b-icon icon="plus"></b-icon>
          Add Employee
        </b-button>
      </slot>
    </div>

    <b-modal
      v-model="showModal"
      title="Add User"
      @hidden="reset()"
      @shown="init()"
      @ok="createUser"
      ok-variant="primary"
      no-close-on-backdrop
      no-close-on-esc
    >
      <b-form-group
        label="First Name"
        label-align="left"
      >
        <b-input v-model="firstname"></b-input>
      </b-form-group>
      <b-form-group
        label="Last Name"
        label-align="left"
      >
        <b-input v-model="lastname"></b-input>
      </b-form-group>
      <b-form-group
        label="Email"
        label-align="left"
      >
        <b-input v-model="email"></b-input>
      </b-form-group>
      <b-form-group
        label="Permission Level"
        label-align="left"
      >
        <b-form-select
          v-model="permission"
          :options="permissions"
        ></b-form-select>
      </b-form-group>
      <b-form-group
        label="Team Name"
        label-align="left"
      >
        <b-input v-model="teamname"></b-input>
      </b-form-group>
      <b-form-group
        label="Calendar Path"
        label-align="left"
      >
        <b-input v-model="calendar"></b-input>
      </b-form-group>

      <template #modal-ok>
        <b-icon icon="check"></b-icon> Save
      </template>
      <template #modal-cancel>
        <b-icon icon="x"></b-icon> Cancel
      </template>
    </b-modal>
  </div>
</template>
<script>
import AdminService from '@/services/AdminService'

export default {
  data () {
    return {
      showModal: false,
      firstname: null,
      lastname: null,
      email: null,
      teamname: null,
      calendar: null,
      permission: 20,
      permissions: [
        { value: 40, text: 'Data Entry' },
        { value: 20, text: 'Bookkeeper' },
        { value: 10, text: 'Administrator' }
      ]
    }
  },

  props: {
    id: {
      type: Number,
      default: 0
    },
    edit: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    init () {
      if (this.id > 0) {
        const filters = {
          id: this.id,
          company_id: null,
          organization_id: null
        }

        AdminService.getUsers(filters).then(
          (response) => {
            const user = response.data.info.users[0]

            this.firstname = user.firstname
            this.lastname = user.lastname
            this.email = user.email
            this.permission = user.role_id
            this.teamname = user.teamname
            this.calendar = user.calendar
          }
        )
      } else {
        this.reset()
      }
    },

    reset () {
      this.firstname = null
      this.lastname = null
      this.email = null
      this.permission = 20
      this.teamname = null
      this.calendar = null
    },

    createUser (bvModalEvt) {
      bvModalEvt.preventDefault()
      const data = {
        id: this.id,
        firstname: this.firstname,
        lastname: this.lastname,
        email: this.email,
        role_id: this.permission,
        teamname: this.teamname,
        calendar: this.calendar
      }
      AdminService.updateEmployee(data).then(
        (response) => {
          this.$aimNotify.notify(response)

          this.$nextTick(() => {
            this.showModal = false
            this.$emit('refresh')
          })
        }
      ).catch((err) => {
        this.$aimNotify.error(err.response)
        console.dir(err)
      })
    }
  }
}
</script>
